require('../scss/app.scss');
// require('./svg/svg.js');

window.noZensmooth = true;
//
// require('./common/true-vh');
// require('./common/goal');
// require('./common/js-validation');
// require('./common/links');
// require('./common/main');
// require('./common/pagination');
// require('./common/respond');
// require('./common/map');
// require('./common/need-animation');
// require('./common/webp-polyfill');
//
// require('./accordion/initializator');
// require('./accordion/switchable-accordion');
//
// require('./field/phone.js');
// require('./field/range.js'); // Раскоментить при необходимости
// require('../components/smart-tabs/smart-tabs');
require('./scroll');