function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function scroll(scrollPlus, block, speed) {
  while (true) {
    if (window.scrollNow) {
         block.scrollTop = block.scrollTop + scrollPlus;
    }
    await sleep(speed);
  }
}

function scrollDefine() {
    const block = document.querySelector('[data-scrollable-block]');
    const speed = parseInt(block.dataset.scrollSpeed, 10);
    const scrollPlus = parseInt(block.dataset.autoScrollScale, 10);
    scroll(scrollPlus || 1, block, speed);
}

function toggleAutoplayClass() {
  const container = document.querySelector('.index-page__container');
  container.classList.remove('_autoplay');
  if (window.scrollNow) {
    container.classList.add('_autoplay');
  }
}

function stopDefAction(evt) {
  if (evt.code === 'Space') {
    evt.preventDefault();
    const block = document.querySelector('[data-scrollable-block]');
    const scale = parseInt(block.dataset.scrollScale, 10);
    const height = window.innerHeight;
    const scrollPlus = (height * scale) / 100;
    block.scrollTop += scrollPlus;
  }

  if (evt.code === 'Enter') {
    evt.preventDefault();
    window.scrollNow = !window.scrollNow;
    toggleAutoplayClass();
  }
}

function toggleAutoplay(e) {
    e.preventDefault();
    e.stopPropagation();
    window.scrollNow = !window.scrollNow;
    toggleAutoplayClass();
}

function openFullscreen() {
  alert(document.body.requestFullscreen);
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
    document.documentElement.msRequestFullscreen();
  }
}

/* Close fullscreen */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
}

var fullscreen = false;
function toggleFullscreen() {
  if (fullscreen) {
    closeFullscreen();
    fullscreen = false;
  } else {
    openFullscreen();
    fullscreen = true;
  }
}


// var mylatesttap;
// function tapHandler(event) {
//   var now = new Date().getTime();
//   var timesince = now - mylatesttap;
//   if((timesince < 600) && (timesince > 0)) {
//     alert('Doubletap');
//   }
//   mylatesttap = new Date().getTime();
// }

window.scrollNow = false;
window.clickTimer = null;
scrollDefine();
document.addEventListener('keydown', stopDefAction);
document.querySelector('[data-autoplay]').addEventListener('click', toggleAutoplay);
document.querySelector('[data-fullscreen]').addEventListener('click', toggleFullscreen);
// document.addEventListener('touchstart', tapHandler);